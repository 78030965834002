/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeRemotePatchPasswordRecovery } from '~/main/factories/usecases/password/PatchPasswordRecovery';
import history from '~/main/routes/History';
import {
  IconArrowLeft,
  IconNewLogoV4h,
  IconPadlock,
} from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import Input from '~/presentation/components/UI/input/index';
import Linkto from '~/presentation/components/UI/link';
import { schema } from '~/validation/validators/password/RecoverPasswordValidator';
import { PatchPasswordRecovery as PasswordValidate } from '~/domain/usecases/password/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { PasswordValidationBar } from '~/presentation/components/passwordValidationBar';
import { PasswordInstructions } from '../CreateUsersConfirmation/styles/CreateUsersConfirmationStyled';
import { iValidateRestorePass } from './interface';
import {
  Body,
  Container,
  Content,
  Footer,
  Title,
  Span,
  ContainerButtons,
  CancelButton,
  Header,
  Back,
} from './styles/ValidateRestorePassPageStyled';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const ValidateRestorePass: React.FC = (): JSX.Element => {
  const [validationPass, setValidationPass] = useState<iValidateRestorePass>({
    password: '',
    token: '',
  });
  const [passconfirm, setPassConfirm] = useState<string>('');
  const [erro, setErro] = useState<boolean>(false);
  const [score, setScore] = useState(0);
  const query = new URLSearchParams(useLocation().search);
  const tokenResult = query.get('token');

  const today = new Date();

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      ...validationPass,
    },
  });

  useEffect(() => {
    setValidationPass({ ...validationPass, token: tokenResult! });
  }, []);

  const validatePassword = () => {
    if (
      validationPass.password === passconfirm &&
      validationPass.password !== '' &&
      tokenResult !== null
    ) {
      if (score < 5) {
        return AlertMessage({
          message: intl.formatMessage({
            id: 'A senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.',
          }),
          type: 'danger',
        });
      }

      const dataToSend: PasswordValidate.Params = {
        password: validationPass.password,
        token: tokenResult!,
      };

      try {
        const validate = schema.parse(dataToSend);

        makeRemotePatchPasswordRecovery()
          .patch({
            password: validationPass.password,
            token: tokenResult!,
          })
          .catch(() =>
            AlertMessage({
              message: intl.formatMessage({
                id: 'Você realmente solicitou a alteração de senha?',
              }),
              type: 'danger',
            }),
          );
      } catch (e) {
        console.error(e);
      }
    } else if (validationPass.password === '' && passconfirm === '') {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Digite e confirme sua nova senha.',
        }),
        type: 'danger',
      });
    } else if (passconfirm === '') {
      AlertMessage({
        message: intl.formatMessage({ id: 'Confirme sua nova senha.' }),
        type: 'danger',
      });
    } else {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Você realmente solicitou uma alteração de senha?',
        }),
        type: 'danger',
      });
    }
  };

  function onChangePassword(value: string) {
    setValidationPass({ ...validationPass, password: value });
    setValue('password', value);
  }

  const onSubmit = handleSubmit(data => {
    setValidationPass({ ...validationPass, ...data });
  });

  useEffect(() => {
    if (validationPass.password === passconfirm) {
      setErro(false);
    } else {
      setErro(true);
    }
  }, [passconfirm]);

  function goBack() {
    history.push('/');
  }

  return (
    <Container onSubmit={onSubmit}>
      <Header>
        <Back onClick={goBack}>
          <IconArrowLeft />
          {translator('Voltar')}
        </Back>
        <div />
      </Header>
      <Body>
        <Content id="validateRestoreAuthentication">
          <IconNewLogoV4h />
          <Title>Redefenir senha</Title>
          <Span>
            {translator('Digite e confirme sua nova senha nos campos abaixo.')}
          </Span>
          <ContainerButtons>
            <Input
              id="validateInput"
              name="password"
              type="password"
              label="Nova senha"
              onChange={e => onChangePassword(e.target.value)}
              register={() => register('password')}
              error={Boolean(errors.password)}
              message={errors?.password?.message}
              required
              placeholder="Digite sua senha"
              autoFocus
              icon={IconPadlock}
            />
            <Input
              error={erro}
              id="validatePassword"
              type="password"
              name="validatePassword"
              onChange={e => setPassConfirm(e.target.value)}
              value={passconfirm}
              label="Confirmar sua senha"
              required
              placeholder="Digite sua senha"
              icon={IconPadlock}
            />

            <PasswordInstructions>
              {translator(
                '● A senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.',
              )}
            </PasswordInstructions>

            <PasswordValidationBar
              password={validationPass.password}
              updateScore={setScore}
            />

            <Button
              onClick={validatePassword}
              id="restoreSubmit"
              type="submit"
              rounded
              height="48px"
            >
              Enviar
            </Button>
          </ContainerButtons>
        </Content>
      </Body>
      <Footer>
        <div className="links">
          {`© ${today.getFullYear()} Copyright | `}
          <Linkto
            to="https://minio.v4h.cloud/public/V4H_Poli%CC%81tica_de_Privacidade.pdf"
            title={translator('Política de privacidade')}
          />
          e
          <Linkto
            to="https://minio.v4h.cloud/public/V4H_Termos_de_Uso.pdf"
            title={translator('Termos de uso')}
          />
        </div>
      </Footer>
    </Container>
  );
};

export default ValidateRestorePass;
